<template>
  <!-- 个人担保模块 -->
  <div class="personalGuarantee">
    <title-center name="个人担保" :rules="true"></title-center>
    <div class="item">
      <div class="title">
        <div class="headTop">
          <div>
            <font style="color: #f56c6c">*</font>签约主体：
            <base-select
              v-model="loanAssistance"
              style="width: 200px; margin-right: 10px"
              :options="mainPartData"
              :selectedField="['keyId', 'enterpriseName']"
              @change="changeType"
            ></base-select>
          </div>
          <div class="addbtn">
            <div class="addbutton" @click="addPersonal">
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <!-- 建行 -->
      <base-table
        :columns="personalGuarantee"
        :tableAttrs="{
          data: personalData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
        v-if="dictType==='1'"
      >
      <!-- slot-scope="scope" -->
        <template slot="code"  slot-scope="scope">
          <base-table
            :columns="personalFormChildren"
            :tableAttrs="{
              data: scope.row.personContactInfos,
              stripe: true,
            }"
            :isCaculateHeight="false"
            :showPage="false"
          >
           <template slot="action" slot-scope="scope">
          <icon-Button
            @click="removePersonaChildren(scope.row,scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          ></icon-Button>
        </template>
          </base-table>
        </template>
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="action" slot-scope="scope">
          <icon-Button
            @click="removePersona(scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          ></icon-Button>
        </template>
      </base-table>
      <!-- 财富资金版本 -->
      <!-- 中小担版本 -->
      <base-table
        :columns="newpersonalForm"
        :tableAttrs="{
          data: personalData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
         v-if="dictType==='3'||dictType==='4'"
      >
      <!-- slot-scope="scope" -->
      <!-- 插入子表格 -->
        <template slot="code"  slot-scope="scope">
          <base-table
            :columns="personalFormChildren"
            :tableAttrs="{
              data: scope.row.personContactInfos,
              stripe: true,
            }"
            :isCaculateHeight="false"
            :showPage="false"

          >
           <template slot="action" slot-scope="scope">
          <icon-Button
            @click="removePersonaChildren(scope.row,scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          ></icon-Button>
        </template>
          </base-table>
        </template>
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="action" slot-scope="scope">
          <icon-Button
            @click="removePersona(scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          ></icon-Button>
        </template>
      </base-table>
    </div>
    <!-- 添加个人担保弹窗 -->
    <add-personal
      :keyId="businessId"
      :visible="personalState"
      @emitVisible="personalState = false"
      @addPersonal="addPersonalData"
      :personalData="personalData"
      :gysId="finBusuinessInfo.gysId"
      :schemeInfoList="schemeInfoList"
      :dictType='dictType'
    ></add-personal>
  </div>
</template>
<script>
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import titleCenter from '../../components/title-center.vue'
import { personalForm, personalFormChildren, newpersonalForm } from '../utils/config'
import AddPersonal from './add-personal.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
export default {
  components: { titleCenter, BaseTable, AddPersonal, IconButton, BaseSelect },
  name: 'personalGuarantee',
  props: {
    dictType: String, // 产品类型
    personalData: Array, // 外层传入选中回显的数据
    finBusuinessInfo: Object,
    businessId: String,
    schemeInfoList: Array,
    mainPartData: Array,
    personalSelect: String,
    subject: Array
  },
  data () {
    return {
      personalState: false,
      loanAssistance: ''
    }
  },
  computed: {
    personalFormChildren () {
      return personalFormChildren(this)
    },
    personalGuarantee () {
      return personalForm(this)
    },
    newpersonalForm () {
      return newpersonalForm(this)
    }
  },
  watch: {
    personalSelect (val) {
      if (val) {
        this.loanAssistance = val // 回显申请主体
      }
    }
    // personalData(val){
    //   log()
    // }
  },

  methods: {
    // 删除个人担保关系子表数据
    removePersonaChildren (row, index) {
      this.personalData.forEach((item, key) => {
        item.personContactInfos.forEach((ele, index) => {
          if (ele.idCard === row.idCard) {
            this.personalData[key].personContactInfos.splice(index, 1)
            this.personalData[key].chosePersonContactInfos.splice(index, 1)
          }
        })
      })
    },
    // 选择担保主体
    changeType (data, arr, index) {
      console.log(this.personalData, '009090909090')
      this.addPersonalData(this.personalData, 'change')
    },
    // 添加个人担保
    addPersonal () {
      if (!this.loanAssistance) {
        return this.warning('请先选择个人担保签约主体')
      } else {
        this.personalState = true
      }
    },
    // 克隆对象
    cloneObj (obj) {
      var newObj = {}
      if (obj instanceof Array) {
        newObj = []
      }
      for (var key in obj) {
        var val = obj[key]
        newObj[key] = typeof val === 'object' ? this.cloneObj(val) : val
      }
      return newObj
    },
    selectSubject () {},
    // 添加个人担保数据
    addPersonalData (data = [], type) {
      console.log(data, '需要处理的数据')
      const newData = JSON.parse(JSON.stringify(data))
      // const newData = this.cloneObj(data)
      const [selectData] = this.mainPartData.filter(
        (item) => item.keyId === this.loanAssistance
      )
      if (Array.isArray(newData)) {
        newData.forEach((item) => {
          if (selectData) {
            item.loanAssistanceOrg = selectData.enterpriseName
            item.loanAssistanceOrgId = selectData.keyId
          } else {
            item.loanAssistanceOrg = null
            item.loanAssistanceOrgId = null
          }
          // 修改担保主体时,处理当前已添加的表格项 无需重新赋值姓名key
          if (type !== 'change') {
            item.personnalName = item.personnerName
            item.personnalid = item.keyId
          }
          // 修改个人担保关系
          item.personContactInfos = item.chosePersonContactInfos
          // if (item.chosePersonContactInfos && item.chosePersonContactInfos.length > 0) {
          //   item.personContactInfos = item.chosePersonContactInfos
          // }
        })
      }
      this.$emit('changePersonalData', JSON.parse(JSON.stringify(newData)))
    },
    //  删除个人担保数据
    removePersona (index) {
      this.personalData.splice(index, 1)
      if (this.personalData.length === 0) {
        this.loanAssistance = ''
      }
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
