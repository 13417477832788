var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"otherNote"},[_c('base-form',{ref:"otherFrom",staticClass:"formData",attrs:{"componentList":_vm.otherNoteForm,"formAttrs":{
      model: _vm.formData,
      labelWidth: '100px',
    },"showBtns":false}}),(_vm.dictType === '4')?_c('div',[_c('title-center',{attrs:{"name":"抵质押物","rules":false}}),_c('div',[_c('div',{staticClass:"addbtn"},[_c('div',{staticClass:"addbutton",on:{"click":_vm.add}},[_c('i',{staticClass:"el-icon-plus"})])])]),_c('base-table',{attrs:{"columns":_vm.pledgeConfig,"tableAttrs":{
        data: _vm.finPawnInfos,
        stripe: true,
      },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"index",fn:function(scope){return [_vm._v(_vm._s(scope.$index + 1))]}},{key:"pawnName",fn:function(scope){return [_c('base-input',{attrs:{"type":"textarea","placeholder":"请输入","rows":"5","maxlength":"250"},model:{value:(scope.row.pawnName),callback:function ($$v) {_vm.$set(scope.row, "pawnName", $$v)},expression:"scope.row.pawnName"}})]}},{key:"pawnValue",fn:function(scope){return [_c('base-input',{attrs:{"type":"textarea","placeholder":"请输入","rows":"5","maxlength":"250"},model:{value:(scope.row.pawnValue),callback:function ($$v) {_vm.$set(scope.row, "pawnValue", $$v)},expression:"scope.row.pawnValue"}})]}},{key:"action",fn:function(scope){return [_c('icon-button',{attrs:{"content":"删除","icon":"iconfont iconshanchu1"},on:{"click":function($event){return _vm.remove(scope.$index)}}})]}}],null,false,1481612970)})],1):_vm._e(),(_vm.dictType === '4')?_c('div',[_c('title-center',{attrs:{"name":"还款计划","rules":true}}),_c('div',{staticClass:"otherNotePlan"},[_c('base-button',{attrs:{"label":"生成","icon":"iconfont iconshengcheng"},on:{"click":_vm.generateRepaymentPlan}})],1),_c('base-table',{attrs:{"columns":_vm.repaymentPlanConfig,"tableAttrs":{
        data: _vm.finRepayPlan,
        stripe: true,
      },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"index",fn:function(scope){return [_vm._v(_vm._s(scope.$index+1)+" ")]}},{key:"contractRepaymentArr",fn:function(scope){return [_c('base-select',{attrs:{"clearable":true,"multiple":true,"options":_vm.repaymentPlanContract,"selectedField":['keyId', 'contractName']},on:{"change":function($event){return _vm.changeContract(scope.row.contractRepayment,scope.$index)}},model:{value:(scope.row.contractRepayment),callback:function ($$v) {_vm.$set(scope.row, "contractRepayment", $$v)},expression:"scope.row.contractRepayment"}})]}},{key:"repayCondition",fn:function(scope){return [_c('base-input',{attrs:{"maxlength":"500","placeholder":"请选择还款合同"},model:{value:(scope.row.repayCondition),callback:function ($$v) {_vm.$set(scope.row, "repayCondition", $$v)},expression:"scope.row.repayCondition"}})]}}],null,false,1192157072)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }