<template>
  <!-- 补充合同弹窗 -->
  <base-dialog
    :visible='visible'
    width='1100px'
    :showFooter="false"
    @close="emitVisible"
    title="选择">
    <div class="content">
      <!-- 查询条件 -->
      <base-form
        :componentList="formConfig"
        :formAttrs="{
          model: queryParas,
          labelWidth: '90px'
        }"
        class="formStyle"
        :btnReset="false"
        @handleFilter="handleFilter"
        @clearParams="clearParams"
        ref="formData">
      </base-form>
      <!-- 表格数据 -->
      <base-table
        :columns="columns"
        :showPage="true"
        :tableAttrs="{
          stripe: true,
        }"
        ref="tableData"
        :api="api"
        :getApi="'contractPage'"
        :queryParas="queryParas"
        :loadCount="loadCount"
        :dataSource.sync="tableData"
        @selection-change="change"
        :webPage="false"
        :isCaculateHeight='false'>
        <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      </base-table>
    </div>
    <template slot="footer">
      <base-button label='提 交' @click="confirm"></base-button>
      <base-button label='关 闭' type="default" @click="emitVisible"></base-button>
    </template>
  </base-dialog>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import { contractForm, contractTable } from '../utils/add-config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { contactApi } from '@/api/contactApi'
export default {
  name: 'addContractInfo',
  components: { baseDialog, BaseButton, BaseForm, BaseTable },
  props: {
    visible: Boolean,
    businessId: String,
    contractIds: Array
  },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        businessId: this.businessId
      },
      loadCount: 0,
      tableData: [],
      addVisible: false,
      changeData: [],
      addData: []
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.queryParas = {
            pageSize: 10,
            pageIndex: 1,
            businessId: this.businessId
          }
          this.$set(this.queryParas, 'contractIds', this.contractIds.join(','))
          this.handleFilter()
        })
      }
    }
  },
  computed: {
    formConfig () {
      return contractForm(this)
    },
    columns () {
      return contractTable(this)
    },
    api () {
      return contactApi
    }
  },
  methods: {
    emitVisible () {
      this.$emit('emitVisible', false)
    },
    handleFilter () {
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        businessId: this.businessId
      }
    },
    // 选择数据
    change (data) {
      this.changeData = data
    },
    confirm () {
      this.$emit('addContractInfo', this.changeData)
      this.emitVisible()
    }
  }
}
</script>

<style lang='scss' scoped>
.content{
  padding:20px;
  .link {
    color: rgb(53, 121, 248);
    text-decoration: underline;
    cursor: pointer;
  }
}

</style>
