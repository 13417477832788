var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accountAndService"},[_c('title-center',{attrs:{"name":"监管账户"}}),_c('base-form',{ref:"superviseForm",staticClass:"formData",attrs:{"componentList":_vm.superviseForm,"formAttrs":{
      model: _vm.accountData,
      labelWidth: '100px',
    },"showBtns":false},on:{"add":_vm.add}}),(_vm.dictType==='3')?_c('title-center',{attrs:{"name":"保理结算账户"}}):_vm._e(),(_vm.dictType==='3')?_c('base-form',{ref:"settlementAccountForm",staticClass:"formData",attrs:{"componentList":_vm.settlementAccount,"formAttrs":{
      model: _vm.finRepayBankAccountInfoDTO,
      labelWidth: '140px',
    },"showBtns":false}}):_vm._e(),_c('title-center',{attrs:{"name":"保理服务信息"}}),_c('base-form',{ref:"factoringForm",staticClass:"formData",attrs:{"componentList":_vm.factoringRelatedForm,"formAttrs":{
      model: _vm.serviceData,
      labelWidth: '140px',
    },"showBtns":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }