import Rest from '@/utils/http/Rest'
import http from '@/utils/http'

export default class Parameter extends Rest {
  /**
   * 获取表格列表数据
   * @param params
   * @returns {AxiosPromise}
   */
  getTableList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/page`,
      params
    })
  }

  /**
   * 导出表格列表
   * @returns {AxiosPromise}
   */
  exportList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/export`,
      responseType: 'blob',
      params
    })
  }

  /**
   *融资台账总的还款金额
   * @param params
   * @returns {AxiosPromise}
   */
  loanAmountSum (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/loanAmountSum`,
      params
    })
  }

  /** 获取资方类型下拉菜单
   * /api/product/fundsprovider/type
   * @returns {AxiosPromise}
   */
  getFundsproviderType () {
    return http({
      method: 'get',
      url: '/api/product/fundsprovider/type'
    })
  }

  /** 获取资方名称下拉菜单
   * /api/product/fundsprovider/type
   * @returns {AxiosPromise}
   */
  getFundsprovidertName (capitalSideType) {
    return http({
      method: 'get',
      url: `/api/product/fundsprovider/name?capitalSideType=${capitalSideType}`
    })
  }

  /** 获取资方信息下拉菜单
   * /api/product/fundsprovider/info
   * @returns {AxiosPromise}
   */
  getFundsprovidertInfo (params) {
    return http({
      method: 'get',
      url: '/api/product/fundsprovider/info',
      params
    })
  }

  /**
   * 查询一级供应商
   * @returns {AxiosPromise}
   */
  getOneList () {
    return http({
      method: 'get',
      url: '/api/company/supplier/drop'
    })
  }

  /**
   * 查询二级级供应商
   * @returns {AxiosPromise}
   */
  getTwoList (gysId) {
    return http({
      method: 'get',
      url: `/api/finance/plan/getByGys?gysId=${gysId}`
    })
  }

  /**
   * 获取产品下拉列表
   * @returns {AxiosPromise}
   */
  getProductList () {
    return http({
      method: 'get',
      url: '/api/product/platform/list'
    })
  }
}
