<template>
  <!-- 补充合同数据 -->
  <div class="supplementalContractInfo">
    <title-center name="补充合同信息"></title-center>
    <div style="text-align: right; margin-bottom: 10px">
      <div class="addbtn">
        <div class="addbutton" @click="addContract">
          <i class="el-icon-plus"></i>
        </div>
      </div>
    </div>
    <!-- 表格列表数据 -->
    <base-table
      :columns="contractInfoForm"
      :tableAttrs="{
        data: contractInfoData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="index" slot-scope="scope">{{
        scope.$index + 1
      }}</template>
      <template slot="action" slot-scope="scope">
        <IconButton
          @click="removeContract(scope.$index)"
          content="删除"
          icon="iconfont iconshanchu1"
        ></IconButton>
      </template>
    </base-table>
    <!-- 补充合同选择弹窗 -->
    <add-contract-info
      :visible="contractState"
      :businessId="businessId"
      :contractIds='contractIds'
      @emitVisible="contractState = false"
      @addContractInfo="addContractInfoData"
    ></add-contract-info>
  </div>
</template>
<script>
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import titleCenter from '../../components/title-center.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { contractInfo } from '../utils/config'
import AddContractInfo from './add-contract-info.vue'
export default {
  components: { titleCenter, BaseTable, IconButton, AddContractInfo },
  name: 'supplementalContractInfo',
  props: {
    contractInfoData: Array,
    pledgorList: Array, // 质押数据
    alienatorData: Array, // 转让数据
    businessId: String,
    finBusuinessInfo: Object // 详情业务数据
  },
  data () {
    return {
      contractState: false,
      contractIds: []
    }
  },
  computed: {
    contractInfoForm () {
      return contractInfo(this)
    }
  },
  methods: {
    // 新增补充合同信息
    addContract () {
      this.contractIds = []
      this.contractInfoData.forEach(ele => {
        this.contractIds.push(ele.finContractId)
      })
      this.pledgorList.forEach(ele => {
        ele.pledgorData.forEach(ele => {
          this.contractIds.push(ele.keyIds)
        })
      })
      this.alienatorData.forEach(ele => {
        this.contractIds.push(ele.keyIds)
      })
      this.contractState = true
    },
    // 补充合同新增数据
    addContractInfoData (data) {
      data.forEach((ele) => {
        ele.finContractCode = ele.contractCode
        ele.finContractId = ele.keyId
        ele.finContractName = ele.contractName
        ele.gysId = this.finBusuinessInfo.gysId
        ele.projectDebtor = ele.gysName
        ele.finContractAmount = ele.contractAmount
        ele.pledgeValue = ele.contractAmount - ele.paidAmount
      })
      const arr = JSON.parse(JSON.stringify(data))
      arr.forEach(ele => {
        delete ele.keyId
      })

      if (this.alienatorData.length > 0 || this.pledgorList.length > 0) {
        const kg = [...this.alienatorData, ...this.pledgorList]
        for (const j of arr) {
          for (const k of kg) {
            if (j.finContractCode === k.finContractCode) {
              return this.warning('质押信息和转让信息与补录信息的合同不能相同，请重新选择')
            }
          }
        }
      }

      this.contractInfoData.push(...arr)
    },
    // 删除补充合同数据
    removeContract (index) {
      this.contractInfoData.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
