<template>
  <!-- 添加企业担保弹窗 -->
  <base-dialog
    :visible='visible'
    width='1100px'
    :showFooter="false"
    @close="emitVisible"
    class="addEnterprise"
    title="选择">
    <div class="content">
      <!-- 查询条件 -->
      <base-form
        :componentList="formConfig"
        :formAttrs="{
          model: queryParas,
          labelWidth: '90px'
        }"
        class="formStyle"
        @handleFilter="handleFilter"
        @clearParams="clearParams"
        :btnReset='false'
        ref="formData">
      </base-form>
      <!-- 表格数据 -->
       <base-table
        :columns="columns"
        :showPage="true"
        :loadCount="loadCount"
        :tableAttrs="{
           data: tableData,
          stripe: true,
        }"
        ref="tableData"
        :api="api"
        :queryParas.sync="queryParas"
        :dataSource.sync="tableData"
        :getApi="'supplierPage'"
        @selection-change="change"
        :webPage="false"
          @select='select'
        :isCaculateHeight='false'>
        <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      </base-table>
    </div>
    <template slot="footer">
      <base-button label='提 交' @click="confirm"></base-button>
      <base-button label='关 闭' type="default" @click="emitVisible"></base-button>
    </template>
  </base-dialog>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import { enterpriseForm, enterpriseTable } from '../utils/add-config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { contactApi } from '@/api/contactApi'
export default {
  name: 'addEnterprise',
  components: { baseDialog, BaseButton, BaseForm, BaseTable },
  props: {
    visible: Boolean,
    enterpriseData: Array
  },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [],
      changeData: [],
      addVisible: false,
      addData: []
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.clearParams()
        this.tableData = []
        // this.changeData = JSON.parse(JSON.stringify(this.enterpriseData))
        this.changeData = []
        this.$nextTick(() => {
          this.handleFilter()
        })
      }
    },
    tableData: {
      handler (val) {
        if (val.length > 0) {
          const that = this
          that.$refs.tableData.clearSelection()
          this.$nextTick(() => {
            // 回显选中的选项
            that.$refs.tableData.clearSelection()
            for (let i = 0; i < that.tableData.length; i++) {
              for (let j = 0; j < that.enterpriseData.length; j++) {
                if (that.tableData[i].enterpriseName === that.enterpriseData[j].companyName && that.tableData[i].keyId === that.enterpriseData[j].companyId) {
                  that.$refs.tableData.toggleRowSelection(that.tableData[i], true)
                }
              }
            }
            // 显示选中的数据
            console.log(that.tableData, that.changeData, that.enterpriseData)
            for (let i = 0; i < that.tableData.length; i++) {
              for (let j = 0; j < that.changeData.length; j++) {
                if (that.tableData[i].enterpriseName === that.changeData[j].enterpriseName && that.tableData[i].keyId === that.changeData[j].keyId) {
                  that.$refs.tableData.toggleRowSelection(that.tableData[i], true)
                }
              }
            }
          })
        }
      },
      deep: true
    }
  },
  computed: {
    formConfig () {
      return enterpriseForm(this)
    },
    columns () {
      return enterpriseTable(this)
    },
    api () {
      return contactApi
    }
  },
  methods: {
    // getlist () {
    //   this.queryParas.pageSize = 99999
    //   this.api.supplierPage(this.queryParas).then(res => {
    //     this.tableData = res.data.records
    //   })
    // },
    // 改变选项
    select (selection, row) {
      this.changeData.forEach((item, index) => {
        if (item.creditCode === row.creditCode) {
          this.changeData.splice(index, 1)
        }
      })
    },
    emitVisible () {
      this.$emit('emitVisible', false)
    },
    handleFilter () {
      this.loadCount++
      // this.getlist()
    },
    // 选择数据
    change (data) {
      console.log(data, '222')
      // this.changeData = []
      if (data.length === 0) {
        const newArr = []
        this.changeData.forEach((item, index) => {
          console.log(this.tableData.indexOf(item))
          if (this.tableData.indexOf(item) !== -1) {
            newArr.push(item)
          }
        })
        if (newArr.length > 0) {
          newArr.forEach((item) => {
            if (this.changeData.indexOf(item) !== -1) {
              this.changeData.splice(this.changeData.indexOf(item), 1)
            }
          })
        }
      }
      console.log(this.changeData, data, '处理前')
      data.forEach(ele => {
        let state = true
        this.changeData.forEach(eles => {
          if (eles.keyId === ele.keyId) {
            state = false
          }
        })
        if (state) {
          this.changeData.push(ele)
        }
        if (this.changeData.length === 0) {
          this.changeData.push(ele)
        }
      })
      console.log(this.changeData, '处理后')
    },
    confirm () {
      this.$emit('addEnterprise', this.changeData)
      console.log(this.changeData, '11111111111')
      this.emitVisible()
    },
    clearParams () {
      this.queryParas = {
        pageIndex: 1,
        pageSize: 10
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.content{
  padding:20px;
  .link {
    color: rgb(53, 121, 248);
    text-decoration: underline;
    cursor: pointer;
  }
}

.addEnterprise /deep/ .el-dialog {
  height: 73vh !important;
}
</style>
