<template>
  <!-- 监管账户和保理服务信息 -->
  <div class="accountAndService">
    <title-center name="监管账户"></title-center>
    <base-form
      :componentList="superviseForm"
      :formAttrs="{
        model: accountData,
        labelWidth: '100px',
      }"
      @add='add'
      :showBtns="false"
      class="formData"
      ref="superviseForm"
    >
    </base-form>
    <!-- 财富资金显示 -->
    <title-center name="保理结算账户" v-if="dictType==='3'"></title-center>
       <base-form
        v-if="dictType==='3'"
      :componentList="settlementAccount"
      :formAttrs="{
        model: finRepayBankAccountInfoDTO,
        labelWidth: '140px',
      }"
      :showBtns="false"
      class="formData"
      ref="settlementAccountForm"
    >
    </base-form>

    <title-center name='保理服务信息'></title-center>
    <base-form
      :componentList="factoringRelatedForm"
      :formAttrs="{
        model: serviceData,
        labelWidth: '140px',
      }"
      :showBtns="false"
      class="formData"
      ref="factoringForm"
    >
    </base-form>
  </div>
</template>
<script>
import BaseForm from '@/components/common/base-form/base-form.vue'
import titleCenter from '../../components/title-center.vue'
import { supervise, factoringRelated, settlementAccount } from '../utils/config'
export default {
  components: { titleCenter, BaseForm },
  name: 'accountAndService',
  props: {
    finRepayBankAccountInfoDTO: Object, // 保理结算账户
    dictType: String, // 产品类型
    accountData: Object,
    serviceData: Object,
    gysId: String // 供应商Id
  },
  data () {
    return {}
  },
  watch: {

  },
  computed: {
    settlementAccount () {
      return settlementAccount(this)
    },
    superviseForm () {
      return supervise(this)
    },
    factoringRelatedForm () {
      return factoringRelated(this)
    }
  },
  methods: {
    clear () {
      this.$nextTick(() => {
        if (this.$refs.settlementAccountForm) {
          this.$refs.settlementAccountForm.resetFields()
        }
        if (this.$refs.superviseForm) {
          this.$refs.superviseForm.resetFields()
        }
      })
    },
    clearFromValidate () {
      // this.$refs.superviseForm.resetFields()

      this.$nextTick(() => {
        if (this.$refs.settlementAccountForm) {
          this.$refs.settlementAccountForm.resetFields()
        }
      })
      // this.$refs.factoringForm.resetFields()
    },
    addSelectAccount (data) {
      // this.$refs.superviseForm.resetFields()
      this.$emit('addSelectAccount', data)
    },
    add (data) {
      console.log(data, 'changeFinRepayBankAccountInfoDTO')
      // this.$refs.settlementAccountForm.resetFields()
      this.$emit('changeFinRepayBankAccountInfoDTO', data)
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
