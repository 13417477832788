<template>
  <!-- 股东会决议有效性 -->
  <div>
    <title-center name="股东决议有效性" :rules="true"></title-center>
    <base-form
      :componentList="shareholderMeetingConfig"
      :formAttrs="{
        model: formData,
        labelWidth: '140px',
      }"
      :showBtns="false"
      class="formData"
      ref="shareholderMeeting"
    />
  </div>
</template>

<script>
import BaseForm from '@/components/common/base-form/base-form.vue'
import titleCenter from '../../components/title-center.vue'
import { shareholderMeetingConfig } from '../utils/config'
export default {
  components: { titleCenter, BaseForm },
  props: {
    gysId: String, // 供应商ID
    resultValidTimeType: String, // 决议有效性类型
    shareholderResultValidInfo: Object, // 股东会决议有效性信息DTO，
    singleTransactionType: String,
    financeAmount: String
  },
  data () {
    return {
      formData: {
        resultValidTimeType: '0',
        shareholderResultValidInfo: {
          time: [],
          resultValidTimeStart: '',
          resultValidTimeEnd: '',
          guaranteeQuota: '',
          busiApproveTime: '',
          validTimeNum: '',
          validTimeUnit: '',
          keyId: '',
          surplusGuaranteeQuota: ''
        }
      }
    }
  },
  // 计算属性 类似于data概念
  computed: {
    shareholderMeetingConfig () {
      return shareholderMeetingConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {
    resultValidTimeType (val) {
      this.formData.resultValidTimeType = val
    },
    shareholderResultValidInfo: {
      handler (val) {
        this.formData.shareholderResultValidInfo = val
      },
      deep: true
    },
    formData: {
      handler (val) {
        this.$emit('changeResolutionFrom', val)
      },
      deep: true
    }
  },
  // 方法集合
  methods: {
    getValidInfo (data) {
      this.$emit('getValidInfo', data)
    },
    changeSingleTransactionType (data) {
      this.$emit('changeSingleTransactionType', data)
    },
    // 修改年月日
    changeValidTimeUnit (val) {
      this.$emit('changeValidTimeUnit', val)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
/deep/ .el-form-item__content{
  height: auto !important;
}
</style>
