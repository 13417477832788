<template>
  <!-- 其他备注模块 -->
  <div class="otherNote">
    <base-form
      :componentList="otherNoteForm"
      :formAttrs="{
        model: formData,
        labelWidth: '100px',
      }"
      :showBtns="false"
      class="formData"
      ref="otherFrom"
    >
    </base-form>
    <!-- 抵质押物 -->
    <div v-if="dictType === '4'">
      <title-center name="抵质押物" :rules="false"></title-center>
      <div>
        <div class="addbtn">
          <div class="addbutton" @click="add">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <base-table
        :columns="pledgeConfig"
        :tableAttrs="{
          data: finPawnInfos,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="pawnName" slot-scope="scope">
          <base-input
            type="textarea"
            placeholder="请输入"
            v-model="scope.row.pawnName"
            rows="5"
            maxlength='250'
          ></base-input
        ></template>
        <template slot="pawnValue" slot-scope="scope">
            <base-input
            type="textarea"
            placeholder="请输入"
            v-model="scope.row.pawnValue"
            rows="5"
            maxlength='250'
          ></base-input
        >
        </template>
        <template slot="action" slot-scope="scope">
         <icon-button @click="remove(scope.$index)"
         content='删除'
          icon="iconfont iconshanchu1" />
        </template>
      </base-table>
    </div>
    <!-- 还款计划 -->
    <div v-if="dictType === '4'">
      <title-center name="还款计划" :rules="true"></title-center>
      <div class="otherNotePlan">
        <base-button label="生成"  icon="iconfont iconshengcheng" @click="generateRepaymentPlan" />
      </div>
       <base-table
        :columns="repaymentPlanConfig"
        :tableAttrs="{
          data: finRepayPlan,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
      <template slot="index" slot-scope="scope">{{scope.$index+1}} </template>
      <template slot="contractRepaymentArr" slot-scope="scope">
        <base-select
        v-model="scope.row.contractRepayment"
        :clearable="true"
        :multiple='true'
        :options="repaymentPlanContract"
        :selectedField="['keyId', 'contractName']"
        @change="changeContract(scope.row.contractRepayment,scope.$index)"
       ></base-select>
      </template>
      <template slot="repayCondition" slot-scope="scope">
        <base-input v-model="scope.row.repayCondition" maxlength='500' placeholder="请选择还款合同"></base-input>
      </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import BaseInput from '@/components/input/sz-input/sz-input.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { formatDate } from '@/utils/auth/common'
import TitleCenter from '../../components/title-center.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { otherNote, pledgeConfig, repaymentPlanConfig } from '../utils/config'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
export default {
  components: { BaseForm, BaseTable, TitleCenter, BaseInput, IconButton, BaseButton, BaseSelect },
  name: 'otherNote',
  props: {
    formData: Object,
    dictType: String,
    finRepayPlan: Array,
    repaymentPlanContract: Array, // 已选择的合同
    finPawnInfos: Array// 抵质押物数据
  },
  data () {
    return {

    }
  },
  computed: {

    repaymentPlanConfig () {
      return repaymentPlanConfig(this)
    },
    pledgeConfig () {
      return pledgeConfig(this)
    },
    otherNoteForm () {
      return otherNote(this)
    }
  },
  methods: {
    // 选择合同
    changeContract (data, index) {
      let value = ''
      const repaymentPlanContractArr = []
      data.forEach((elem) => {
        // elem 合同id
        this.repaymentPlanContract.forEach((item) => {
          if (item.keyId === elem) {
            if (repaymentPlanContractArr.length === 0) {
              repaymentPlanContractArr.push(this.finRepayPlan[index].isDate ? this.finRepayPlan[index].preRepayDate + '/' + item.contractName : formatDate(this.finRepayPlan[index].preRepayDate, 'YY/MM/DD') + '/' + item.contractName)
            } else {
              repaymentPlanContractArr.push(item.contractName)
            }
          }
        })
      })
      value = repaymentPlanContractArr.join(',')
      this.$set(this.finRepayPlan[index], 'repayContractId', data.join(','))
      this.$set(this.finRepayPlan[index], 'repayCondition', value)
    },
    // 生成
    generateRepaymentPlan () {
      this.$emit('generateRepaymentPlan', '')
    },
    remove (index) {
      this.finPawnInfos.splice(index, 1)
    },
    add () {
      this.finPawnInfos.push({})
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
