<template>
  <!-- 财富资金 -->
  <div class="decisionMake">
    <backtitle lable="融资决议管理"></backtitle>
    <div class="mainContent">
      <!-- 右侧楼层 -->
      <div class="point">
        <div class="point-top"><i class="iconfont icondaohang"></i></div>
        <div
          v-for="(item, index) of point"
          :key="index"
          class="item"
          :class="{ itemactive: active === item.id }"
          @click="touchactive(item.id)"
        >
          <span>{{ item.label }}</span>
        </div>
        <div class="scrollTop" @click="backTop">
          <i class="iconfont icontop"></i>
        </div>
      </div>
      <div class="left">
        <div class="header">
          <title-template
            :name="'供应商：' + (finBusuinessInfo.gysCompanyName || '')"
          >
            <template slot="operation">
              <div style="flex: 1"></div>
              <div class="item" style="flex: 1; min-width: 650px">
                <font style="color: #f56c6c">*</font>上会日期：
                <el-date-picker
                  v-model="formData.meettingDate"
                  :picker-options="startDatePicker"
                  placeholder="请选择"
                  type="date"
                  :value-format="'yyyy-MM-dd'"
                  style="width: 150px"
                  @change="changePreLoanDate"
                ></el-date-picker>
                <font style="color: #f56c6c;margin-left: 10px;">*</font>预计放款时间
                <el-date-picker
                  v-model="formData.preLoanDate"
                  :picker-options="pickerOptions"
                  placeholder="请选择"
                  type="date"
                  :value-format="'yyyy-MM-dd'"
                  style="width: 150px; margin-right: 10px"
                ></el-date-picker>

                <base-button
                  label="审核履历"
                  icon="iconfont iconzuo"
                  style="float: right"
                  @click="drawervisible = true"
                  class="examine"
                ></base-button>
              </div>
            </template>
          </title-template>
        </div>
        <div style="clear: both"></div>
        <div class="scrollbox" id="contentRZJY" ref="contentMain">
          <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
              <template slot="title">
                <text-title index="01" label="融资方案"></text-title>
              </template>
              <!-- 融资方案 -->
              <financing-plan
                :mainPartData="mainPartData"
                :payeeData="payeeData"
                ref="financingData"
                :businessId="businessId"
                :detailData="detailData"
                :formData="formData"
                :schemeInfoList="schemeInfoList"
                :pledgorList="pledgorList"
                :dictType="dictType"
              ></financing-plan>
            </el-collapse-item>
            <el-collapse-item name="2">
              <template slot="title">
                <text-title index="02" label="担保措施"></text-title>
              </template>
              <div class="measure">
                <!-- 个人担保 -->
                <personal-guarantee
                  :personalSelect="personalSelect"
                  :mainPartData="mainPartData"
                  :businessId="businessId"
                  @changePersonalData="changePersonalData"
                  :personalData="personalData"
                  :finBusuinessInfo="finBusuinessInfo"
                  :schemeInfoList="schemeInfoList"
                  :subject.sync="personalSubject"
                  :dictType="dictType"
                ></personal-guarantee>
                <!-- 企业担保 -->
                <enterprise-guarantee
                  :enterpriseSelect="enterpriseSelect"
                  :mainPartData="mainPartData"
                  :enterpriseData="enterpriseData"
                  @changeEnterpriseData="changeEnterpriseData"
                  :subject.sync="enterpriseSubject"
                  :dictType="dictType"
                ></enterprise-guarantee>
              </div>
                 <!-- 股东会决议有效性 -->
              <div>
                <shareholder-meeting-resolution
                  ref='shareholderData'
                  :singleTransactionType="singleTransactionType"
                  @getValidInfo="getValidInfo"
                  :resultValidTimeType="resultValidTimeType"
                  :shareholderResultValidInfo="shareholderResultValidInfo"
                  :gysId="finBusuinessInfo.gysId"
                  @changeSingleTransactionType="changeSingleTransactionType"
                  @changeShareholderMeetingResolution='changeShareholderMeetingResolution'
                  @changeResolutionFrom='changeResolutionFrom'
                  @changeValidTimeUnit='changeValidTimeUnit'
                  :financeAmount='schemeInfoList[0].financeAmount'
                />
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                <text-title index="03" label="风控措施"></text-title>
              </template>
              <!-- 监管账户和保理服务信息 -->
              <account-and-service
                :accountData="accountData"
                ref="accountAndService"
                :serviceData="serviceData"
                :dictType="dictType"
                :gysId="finBusuinessInfo.gysId"
                :finRepayBankAccountInfoDTO="finRepayBankAccountInfoDTO"
                @addSelectAccount="addSelectAccount"
                @changeFinRepayBankAccountInfoDTO="
                  changeFinRepayBankAccountInfoDTO
                "
              ></account-and-service>
              <!-- 转让信息 -->
              <transfer-info
                :pledgorList="pledgorList"
                :alienatorData="alienatorData"
                :contractInfoData="contractInfoData"
                :finBusuinessInfo="finBusuinessInfo"
              ></transfer-info>
              <!-- 补充合同信息 -->
              <supplemental-contract-info
                :businessId="businessId"
                :contractInfoData="contractInfoData"
                :pledgorList="pledgorList"
                :alienatorData="alienatorData"
                :finBusuinessInfo="finBusuinessInfo"
              ></supplemental-contract-info>
            </el-collapse-item>
            <el-collapse-item title="签署承诺函" name="4">
              <template slot="title">
                <text-title index="04" label="签署承诺函"></text-title>
              </template>
              <!-- 签署承诺函 -->
              <base-input
                type="textarea"
                placeholder="请输入"
                v-model="commitmentLetter"
                rows="5"
              ></base-input>
            </el-collapse-item>
            <el-collapse-item title="其他备注" name="5">
              <template slot="title">
                <text-title index="05" label="其他备注"></text-title>
              </template>
              <!-- 其他备注 -->
              <other-note
                ref="otherData"
                :dictType="dictType"
                :formData="formData"
              ></other-note>
            </el-collapse-item>
            <el-collapse-item title="文件生成与下载" name="6">
              <template slot="title">
                <text-title index="06" label="文件生成与下载"></text-title>
              </template>
              <base-form
                :componentList="otherFile"
                :formAttrs="{
                  model: fileFormData,
                  labelWidth: '100px',
                }"
                :showBtns="false"
                class="formData"
                ref="otherFile"
              >
              </base-form>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <drawer
        :visible.sync="drawervisible"
        auditType="RZJY"
        name="审核履历"
        style="width: 480px; right: 0px"
        :dataList="dataList"
        :auditInfo="auditInfo"
      ></drawer>
    </div>
    <!-- <previewDialog
      :previsible.sync="previsible"
      :fileId="generatefileId"
      :fileType="fileType"
      :count="count"
    ></previewDialog> -->
    <pre-view :fileId="generatefileId" :isOpen='true' :fileType="fileType" :count="count" />
    <div class="footer">
      <base-button label="保 存" @click="saveData('save')"></base-button>
      <base-button label="提 交" @click="saveData('')"></base-button>
      <base-button label="关 闭" type="default" @click="close"></base-button>
    </div>
  </div>
</template>

<script>
import PreView from '@/components/pre-view/pre-view.vue'
import BaseInput from '@/components/input/sz-input/sz-input.vue'
import drawer from '@/pages/business/components/drawer.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import { schemeInfo, factoringRelated, otherFile } from '../utils/config'
import { resolutionAPi } from '@/api/businessApi'
import TitleTemplate from '../../components/titleTemplate.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import { getDictLists } from '@/filters/fromDict'
import { contactApi } from '@/api/contactApi'
import Backtitle from '../../components/backtitle.vue'
import { formatDate } from '@/utils/auth/common'
import { allSupportFileTypes } from '@/components/pre-view/pre-view'
import FinancingPlan from '../components/financing-plan.vue'
import PersonalGuarantee from '../components/personal-guarantee.vue'
import EnterpriseGuarantee from '../components/enterprise-guarantee.vue'
import AccountAndService from '../components/account-and-service.vue'
import TransferInfo from '../components/transfer-info.vue'
import SupplementalContractInfo from '../components/supplemental-contract-info.vue'
import OtherNote from '../components/other-note.vue'
import ShareholderMeetingResolution from '../components/shareholder-meeting-resolution.vue'
export default {
  name: 'decisionMake',
  props: {
    dictType: String
  },
  components: {
    drawer,
    baseButton,
    BaseForm,
    TitleTemplate,
    TextTitle,
    Backtitle,
    FinancingPlan,
    PersonalGuarantee,
    EnterpriseGuarantee,
    AccountAndService,
    TransferInfo,
    SupplementalContractInfo,
    OtherNote,
    BaseInput,
    PreView,
    ShareholderMeetingResolution
  },
  data () {
    return {
      finRepayBankAccountInfoDTO: {},
      scrollTop: 0,
      commitmentLetter: '',
      enterpriseSubject: [],
      personalSubject: [],
      count: 0,
      fileType: '',
      previsible: false,
      generatefileId: '',
      fileFormData: {},
      pass: false,
      point: [
        { label: '融资方案', id: 0 },
        { label: '担保措施', id: 1 },
        { label: '风控措施', id: 2 },
        { label: '签署承诺函', id: 3 },
        { label: '其他备注', id: 4 },
        { label: '文件生成', id: 5 }
      ],
      active: 0,
      drawervisible: false,
      formData: {
        financeType: '',
        signType: '',
        suppliersSealType: '',
        meettingDate: '',
        preLoanDate: '',
        remark: ''
      },
      pledgorList: [], // 质押合同数据
      accountData: { account: '', remark: '' },
      serviceData: {},
      activeNames: ['1', '2', '3', '4', '5', '6'],
      schemeInfoList: [{ serviceRateStr: '', serviceRateState: false }],
      finBusuinessInfo: {},
      personalData: [],
      enterpriseData: [],
      alienatorData: [],
      contractInfoData: [],
      detailData: {},
      auditInfo: {},
      dataList: [],
      businessId: '',
      keyId: '',
      instanceId: '',
      personsDatas: [],
      pickerOptions: {},
      startDatePicker: {},
      scrollxTime: null,
      mainPartData: [],
      payeeData: [],
      personalSelect: '', // 个人申请类型
      enterpriseSelect: '', // 企业申请类型
      resultValidTimeType: '0', // 股东会决议有效性类型
      shareholderResultValidInfo: {
        resultValidTimeStart: '',
        resultValidTimeEnd: '',
        guaranteeQuota: '',
        busiApproveTime: '',
        validTimeNum: '',
        validTimeUnit: '',
        keyId: '',
        surplusGuaranteeQuota: ''
      }, // 股东会决议有效性信息DTO
      singleTransactionType: '1' // 非单笔默认值
    }
  },
  computed: {
    otherFile () {
      return otherFile(this)
    },
    api () {
      return resolutionAPi
    },
    schemeInfoForm () {
      return function (index) {
        return schemeInfo(this, index)
      }
    },
    factoringRelatedForm () {
      return factoringRelated(this)
    },
    getDictLists () {
      return getDictLists
    }
  },
  created () {
    const self = this
    this.businessId = this.$route.query.keyId
    this.instanceId = this.$route.query.instanceId

    this.pickerOptions = {
      disabledDate (v) {
        // 临时解除不能选择当前日期之前日期
        // return v.getTime() < new Date().getTime() - 86400000
        let disabledTime = new Date('1899-12-31')
        if (self.formData.meettingDate) {
          disabledTime = new Date(self.formData.meettingDate)
        }
        return v.getTime() <= disabledTime
      }
    }
    this.startDatePicker = {
      disabledDate (time) {
        const disabledTime = new Date('1999-12-31')
        return time.getTime() <= disabledTime
      }
    }
  },
  mounted () {
    this.listbylabel()
    this.$nextTick(() => {
      // 获取资金方类型
      this.getBatch()
      window.addEventListener('scroll', this.handleScrollx, true)
    })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScrollx, false)
  },
  watch: {
    'accountData.account': {
      handler (val) {
        this.$nextTick(() => {
          // 清空表单验证提示
          // this.$refs.accountAndService.clearFromValidate()
        })
      },
      deep: true
    },
    'serviceData.capitalCost': {
      handler (val) {
        if (!val) {
          val = 0
        }
        this.$set(
          this.serviceData,
          'yearRate',
          JSON.parse(
            JSON.stringify(
              (parseFloat(val) * 100 +
                parseFloat(
                  this.serviceData.serviceRate
                    ? this.serviceData.serviceRate
                    : 0
                ) *
                  100) /
                100
            )
          )
        )
      },
      deep: true
    },
    'serviceData.serviceRate': {
      handler (val) {
        if (!val) {
          val = 0
        }
        this.$set(
          this.serviceData,
          'yearRate',
          JSON.parse(
            JSON.stringify(
              (parseFloat(val) * 100 +
                parseFloat(
                  this.serviceData.capitalCost
                    ? this.serviceData.capitalCost
                    : 0
                ) *
                  100) /
                100
            )
          )
        )
      },
      deep: true
    },
    schemeInfoList: {
      handler (val) {
        let num = 0
        // 融资金额+融资金额*融资利率*融资期限/12
        val.forEach((ele) => {
          if (ele.financeAmount && ele.financeRate && ele.financeTerm) {
            num =
              num +
              (parseFloat(ele.financeAmount) +
                (((parseFloat(ele.financeAmount) *
                  parseFloat(ele.financeRate)) /
                  100) *
                  parseFloat(ele.financeTerm) *
                  1000000000) /
                  1000000000 /
                  12)
          }
        })
        if (!this.pass) {
          if (num !== 0) {
            this.$set(
              this.serviceData,
              'factoryAmount',
              parseFloat(num).toFixed(2)
            )
          }
        }
      },
      deep: true
    },
    pledgorList: {
      // 一级质押信息赋值给转让信息
      handler (val) {
        if (!this.pass) {
          if (val.length > 0) {
            this.alienatorData = JSON.parse(JSON.stringify(val[0].pledgorData))
          }
        }
      },
      deep: true
    }
  },
  methods: {
    // 修改股东会
    changeValidTimeUnit (data) {
      this.$set(this.shareholderResultValidInfo, 'validTimeUnit', data)
    },
    // 修改股东会决议
    changeResolutionFrom (data) {
      console.log(data, 'newDta')
      this.resultValidTimeType = data.resultValidTimeType
      this.shareholderResultValidInfo = data.shareholderResultValidInfo
    },
    // 清除股东会决议有效性
    clearShareholderMeeting () {
      this.resultValidTimeType = '0'
      this.singleTransactionType = ''
      this.shareholderResultValidInfo = {
        resultValidTimeStart: '',
        resultValidTimeEnd: '',
        guaranteeQuota: '',
        busiApproveTime: '',
        validTimeNum: '',
        validTimeUnit: '',
        keyId: '',
        surplusGuaranteeQuota: ''
      }
    },
    // 修改股东会决议有效性
    changeShareholderMeetingResolution (data) {
      console.log(data, 'data')
    },
    changeSingleTransactionType (data) {
      this.singleTransactionType = data
      this.shareholderResultValidInfo = {
        resultValidTimeStart: '',
        resultValidTimeEnd: '',
        guaranteeQuota: '',
        busiApproveTime: '',
        validTimeNum: '',
        validTimeUnit: '',
        keyId: '',
        surplusGuaranteeQuota: ''
      }
    },
    // 股东会决议有效性
    getValidInfo (data) {
      if (data) {
        // data.time = [data.resultValidTimeStart, data.resultValidTimeEnd]
        for (const key in this.shareholderResultValidInfo) {
          this.$set(this.shareholderResultValidInfo, key, data[key])
        }
      }

      console.log(this.shareholderResultValidInfo, data, '123')
    },
    // 修改监管账号
    addSelectAccount (data) {
      for (const key in data) {
        this.$set(this.accountData, key, data[key])
      }
    },
    // 修改保理账号
    changeFinRepayBankAccountInfoDTO (data) {
      for (const key in data) {
        this.$set(this.finRepayBankAccountInfoDTO, key, data[key])
      }
    },
    // 财富资金产品 融资模式默认设置为一级
    getBatch () {
      let type = ''
      const FINANCING_MODEL = getDictLists('FINANCING_MODEL')
      FINANCING_MODEL.forEach((ele) => {
        if (ele.dictName === '一级模式') {
          type = ele.dictId
          this.$set(this.formData, 'financeType', ele.dictId)
        }
      })
      this.changeFinanceType(type)
      this.detail()
    },
    // 修改个人担保签约主体
    changePersonalData (data) {
      this.personalData = data
    },
    // 修改企业担保签约主体
    changeEnterpriseData (data) {
      this.enterpriseData = data
    },
    // 查询对应企业和员工信息
    listbylabel () {
      contactApi.listbylabel({ enterpriseLabel: 5 }).then((res) => {
        if (res.success) {
          this.mainPartData = res.data
        }
      })
      // 查询服务费率收款主体
      contactApi.listbylabel({ enterpriseLabel: 6 }).then((res) => {
        if (res.success) {
          this.payeeData = res.data
        }
      })
    },
    changePreLoanDate () {
      this.$set(this.formData, 'preLoanDate', '')
    },
    // 浏览器滚动事件设置选择
    handleScrollx () {
      if (!document.getElementById('contentRZJY')) {
        return false
      }
      const jump = document.querySelectorAll('.el-collapse-item')
      if (this.scrollTop <= document.getElementById('contentRZJY').scrollTop) {
        // 从上往下滚动
        this.scrollTop = document.getElementById('contentRZJY').scrollTop

        if (this.scrollTop === 0) {
          this.active = 0
        }
        for (let i = 0; i < jump.length; i++) {
          jump[i].index = i
          if (
            this.scrollTop >= jump[i].offsetTop - 10 &&
            this.scrollTop <= jump[i].offsetTop + 180
          ) {
            for (let j = 0; j < jump.length; j++) {
              this.active = i + 1
            }
          }
        }
      } else if (
        this.scrollTop > document.getElementById('contentRZJY').scrollTop
      ) {
        this.scrollTop = document.getElementById('contentRZJY').scrollTop
        if (this.scrollTop === 0) {
          this.active = 0
        }
        for (let i = 0; i < jump.length; i++) {
          jump[i].index = i
          if (jump[i].offsetTop - this.scrollTop <= 0) {
            this.active = i + 1
          }
        }
      }

      clearTimeout(this.scrollxTime)
      this.scrollxTime = setTimeout(() => {
        // 节流
        // 获取需要滚动的距离
        // for (let i = 0; i < jump.length; i++) {
        //   if (scrollTop >= jump[i].offsetTop - 202) {
        //     this.active = i
        //   }
        //   if (i === 3) {
        //     if (scrollTop > jump[2].offsetTop - 10 && scrollTop < jump[2].offsetTop + 202) {
        //       this.active = 3
        //     }
        //   }
        //   if (i === 4) {
        //     if (scrollTop > jump[2].offsetTop + 202 && scrollTop < jump[2].offsetTop + 402) {
        //       this.active = 4
        //     }
        //   }
        // }
      }, 50)
    },
    backTop () {
      this.touchactive(0)
    },
    // 点击楼层，滚动到相应位置
    touchactive (id) {
      this.active = JSON.parse(JSON.stringify(id))
      const jump = document.querySelectorAll('.el-collapse-item')
      // 获取需要滚动的距离
      let total = ''
      // if (id === 4) {
      //   total = jump[id].offsetTop - 652
      // } else {

      // }
      total = jump[id].offsetTop - 195
      // Chrome
      document.getElementById('contentRZJY').scrollTop = total
    },
    // 获取融资决议详情
    async detail () {
      this.pass = true
      this.api.detail({ businessId: this.businessId }).then(async (res) => {
        if (res.success) {
          this.detailData = JSON.parse(JSON.stringify(res.data))
          this.detailData.financeType = this.formData.financeType
          this.commitmentLetter = res.data.commitmentLetter
          // 签约主体回显
          if (this.detailData.finCompanyGuaranteeInfos[0]) {
            // 企业
            this.enterpriseSelect = this.detailData.finCompanyGuaranteeInfos[0]
              .loanAssistanceOrgId
              ? this.detailData.finCompanyGuaranteeInfos[0].loanAssistanceOrgId
              : ''
          }
          if (this.detailData.finPersonnalGuaranteeInfos[0]) {
            // 个人
            this.personalSelect = this.detailData.finPersonnalGuaranteeInfos[0]
              .loanAssistanceOrgId
              ? this.detailData.finPersonnalGuaranteeInfos[0]
                .loanAssistanceOrgId
              : ''
          }
          if (res.data.fileListInfo) {
            this.$set(
              this.fileFormData,
              'fileName',
              res.data.fileListInfo.fileName
            )
            this.generatefileId =
              res.data.fileListInfo.fileId &&
              res.data.fileListInfo.fileId !== '0'
                ? res.data.fileListInfo.fileId
                : ''
          }
          this.keyId = res.data.keyId
          this.finBusuinessInfo = res.data.finBusuinessInfo
          // 融资方案信息保存为0处理
          if (this.detailData.finPlanInfos) {
            this.detailData.finPlanInfos.forEach((item) => {
              item.mainBorrowerId =
                parseFloat(item.mainBorrowerId) === 0
                  ? ''
                  : item.mainBorrowerId
              item.coBorrowerId =
                parseFloat(item.coBorrowerId) === 0 ? '' : item.coBorrowerId
              item.capitalSideId =
                parseFloat(item.capitalSideId) === 0 ? '' : item.capitalSideId
              item.financeRate =
                parseFloat(item.financeRate) === 0 ? '' : item.financeRate
              item.financeTerm =
                parseFloat(item.financeTerm) === 0 ? '' : item.financeTerm
              // item.financeAmount = parseFloat(item.financeAmount) === 0 ? '' : item.financeAmount
            })
          }
          // 保理服务信息保存为0处理
          if (this.detailData.finFactoringServiceInfo) {
            this.factoringRelatedForm.forEach((item) => {
              if (
                parseInt(this.detailData.finFactoringServiceInfo[item.prop]) ===
                0
              ) {
                this.detailData.finFactoringServiceInfo[item.prop] = ''
              }
            })
          }

          // 股东会决议有效性回显
          this.resultValidTimeType = res.data.resultValidTimeType === '0' ? res.data.resultValidTimeType : '1'
          this.singleTransactionType = res.data.resultValidTimeType === '0' ? '1' : res.data.resultValidTimeType
          // 非单笔 查询 股东会决议有效性
          if (this.resultValidTimeType !== '0') {
            const params = {
              businessId: this.businessId
            }
            if (this.singleTransactionType === '1') {
              resolutionAPi.getShareholderResultValidInfo(params).then(res => {
                if (res.data) {
                  this.shareholderResultValidInfo = res.data
                  // this.$set(this.shareholderResultValidInfo, 'time', [res.data.resultValidTimeStart, res.data.resultValidTimeEnd])
                }
              })
            } else if (this.singleTransactionType === '2') {
              resolutionAPi.getResultValid(params).then(res => {
                if (res.data && res.data.length > 0) {
                  res.data.forEach((item) => {
                    if (item.selected) {
                      this.shareholderResultValidInfo = item
                      // this.$set(this.shareholderResultValidInfo, 'time', [item.resultValidTimeStart, item.resultValidTimeEnd])
                    }
                  })
                }
              })
            }
          }
          if (this.finBusuinessInfo) {
            const { data } = await contactApi.contractList({
              businessId: this.businessId,
              mainBorrowerId: this.finBusuinessInfo.gysId
            })
            this.pledgorList = []
            this.pledgorList.push({
              name: this.finBusuinessInfo.gysCompanyName,
              creditCode: this.finBusuinessInfo.creditCode,
              gysId: this.finBusuinessInfo.gysId,
              pledgorData: [],
              contractData: data
            })
          }
          this.schemeInfoList =
            res.data.finPlanInfos.length > 0
              ? res.data.finPlanInfos
              : [{ serviceRateStr: '', serviceRateState: false }] // 融资方案
          this.schemeInfoList.forEach((ele, index) => {
            const capitalNameAndProductName = `${ele.capitalSideName}-${ele.capitalSideProductName}`
            this.$set(
              ele,
              'capitalNameAndProductName',
              capitalNameAndProductName
            )
            this.$set(ele, 'creditCode', '')
            this.$set(ele, 'idCard', '')
            this.$set(ele, 'serviceRateStr', '')
            this.$set(ele, 'serviceRateState', false)
            this.$set(
              ele,
              'repayTypeCode',
              ele.finRepayStyleDTO
                ? ele.finRepayStyleDTO.repayStatus
                  ? ele.finRepayStyleDTO.repayStatus
                  : '0'
                : '0'
            )
            if (!ele.finRepayStyleDTO) {
              this.$set(ele, 'finRepayStyleDTO', {
                advanceRepayTerm: '', // 提前还款期限
                chargeParty: '', // 收费主体
                finPlanId: '', // 融资方案ID
                repayAmount: '', // 还款金额
                repayRate: '', // 还款利率
                repayStatus: '0', // 还款方式
                repayTime: '', // 还款时间(多个时间用逗号隔开)
                repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
              })
            }

            const [obj] = this.$refs.financingData.providertData.filter(
              (item) => item.keyId === ele.capitalSideId
            )
            this.$set(ele, 'surplusQuota', obj ? obj.surplusQuota : 0)
            const arr = []
            let state = true
            if (ele.finServiceCollectInfo) {
              ele.finServiceCollectInfo.forEach((eles) => {
                if (eles.chargeParty) {
                  arr.push(eles.chargeRate + '%(' + eles.chargeParty + ')')
                } else {
                  state = false
                }
              })
              if (ele.finServiceCollectInfo.length === 0) {
                state = false
              }
            } else {
              state = false
            }
            if (state) {
              ele.serviceRateStr = arr.join(',').replace(/,/g, ';')
              ele.serviceRateState = true
            } else {
              ele.serviceRateStr = ele.serviceRate ? ele.serviceRate : ''
              ele.serviceRateState = false
            }
          })
          if (this.detailData.financeType) {
            this.$refs.financingData.companys(
              this.detailData.financeType,
              'detail'
            )
          } else {
            this.setPledgorData('type')
          }
          this.auditInfo = res.data.finReviewStatus
          this.dataList = res.data.finReviewOpinionInfo
          if (res.data.finRepayBankAccountInfoVO) {
            this.finRepayBankAccountInfoDTO =
              res.data.finRepayBankAccountInfoVO
            // this.$refs.accountAndService.clearFromValidate()
          }
          // this.$nextTick(() => {
          //   this.$refs.accountAndService.clearFromValidate()
          // })
          this.accountData = res.data.finSuperviseBankAccountInfoDTO || {
            remark: ''
          } // 监管账号
          this.enterpriseData = res.data.finCompanyGuaranteeInfos || [] // 企业担保
          this.alienatorData = res.data.finContractInfosTransfer || [] // 转让信息

          this.alienatorData.forEach((ele) => {
            // ele.pledgeValue = ele.pledgeValue ? ele.pledgeValue : ''
            // ele.relLoanAmount = ele.relLoanAmount ? ele.relLoanAmount : ''
            this.pledgorList[0].contractData.forEach((eles) => {
              if (eles.keyId === ele.finContractId) {
                ele.keyIds = eles.keyId
              }
            })
          })

          this.contractInfoData = res.data.finContractInfosSupplement || [] // 补充合同信息
          if (res.data.finFactoringServiceInfo) {
            res.data.finFactoringServiceInfo.factoryAmount =
              res.data.finFactoringServiceInfo.factoryAmount.toString()
            if (
              res.data.finFactoringServiceInfo.factoryAmount &&
              res.data.finFactoringServiceInfo.factoryAmount.indexOf('.') < 0
            ) {
              res.data.finFactoringServiceInfo.factoryAmount =
                res.data.finFactoringServiceInfo.factoryAmount + '.00'
            }
          }

          this.serviceData = { ...res.data.finFactoringServiceInfo } || {} // 保理服务信息

          this.personalData = res.data.finPersonnalGuaranteeInfos || [] // 个人担保
          this.formData = {
            remark: res.data.remark,
            suppliersSealType: res.data.finBusuinessInfo
              ? res.data.finBusuinessInfo.suppliersSealType ||
                res.data.finBusuinessInfo.suppliersSealType === 0
                ? res.data.finBusuinessInfo.suppliersSealType.toString()
                : ''
              : '',
            financeType: res.data.financeType,
            signType: res.data.signType,
            meettingDate: res.data.meettingDate
              ? formatDate(res.data.meettingDate, 'YY-MM-DD') !== '--'
                ? formatDate(res.data.meettingDate, 'YY-MM-DD').replace(
                  /\//g,
                  '-'
                )
                : ''
              : '',
            preLoanDate: res.data.preLoanDate
              ? formatDate(res.data.preLoanDate, 'YY-MM-DD') !== '--'
                ? formatDate(res.data.preLoanDate, 'YY-MM-DD').replace(
                  /\//g,
                  '-'
                )
                : ''
              : ''
          }
          this.$nextTick(() => {
            this.$refs.otherData.$refs.otherFrom.clearValidate()
            this.$refs.financingData.$refs.schemeFormData.forEach((ele) => {
              ele.clearValidate()
            })
            this.$refs.accountAndService.$refs.factoringForm.clearValidate()
            this.$refs.accountAndService.$refs.superviseForm.clearValidate()
            if (this.$refs.accountAndService.$refs.settlementAccountForm) {
              this.$refs.accountAndService.$refs.settlementAccountForm.clearValidate()
            }
            this.$refs.otherFile.clearValidate()
          })
          setTimeout(() => {
            this.pass = false
          }, 2000)
        }
      })
    },
    // 切换融资模式
    async changeFinanceType (type) {
      this.$nextTick(() => {
        this.$refs.financingData.$refs.schemeFormData[0].clearValidate()
        this.$refs.accountAndService.$refs.factoringForm.clearValidate()
        this.$refs.accountAndService.$refs.superviseForm.clearValidate()
      })
      const before = this.schemeInfoList[0]
      const baseData = {
        serviceRateStr: '',
        serviceRateState: false,
        repayTypeCode: '0',
        finRepayStyleDTO: {
          advanceRepayTerm: '', // 提前还款期限
          chargeParty: '', // 收费主体
          finPlanId: '', // 融资方案ID
          repayAmount: '', // 还款金额
          repayRate: '', // 还款利率
          repayStatus: '0', // 还款方式
          repayTime: '', // 还款时间(多个时间用逗号隔开)
          repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
        }
      }
      // 原始数据赋值
      if (before) {
        baseData.capitalNameAndProductName = before.capitalNameAndProductName
        baseData.capitalSideName = before.capitalSideName
        baseData.capitalSideProductId = before.capitalSideProductId
        baseData.capitalSideProductName = before.capitalSideProductName
        baseData.capitalSideId = before.capitalSideId
        baseData.financeRate = before.financeRate
      }
      this.schemeInfoList = [baseData]
      this.pledgorList = this.pledgorList[0] ? [this.pledgorList[0]] : []
      this.enterpriseData = []
      this.accountData = { account: '' }
      this.contractInfoData = []
      this.alienatorData = []
      this.serviceData = {}
      this.personalData = []
      const obj = {
        financeType: type,
        signType: '',
        suppliersSealType: '',
        meettingDate: '',
        preLoanDate: ''
      }
      this.$set(this.formData, {}, obj)
      if (type) {
        this.$refs.financingData.companys(type)
        let status = true
        this.schemeInfoList.forEach((ele) => {
          if (
            this.finBusuinessInfo.gysCompanyName &&
            ele.mainBorrower === this.finBusuinessInfo.gysCompanyName
          ) {
            status = false
          }
        })
        if (!status && type === 'SECOND_MODEL') {
          return this.warning('主借人不能相同')
        }
        if (type === 'ONCE_MODEL') {
          if (this.finBusuinessInfo) {
            const { data } = await contactApi.contractList({
              businessId: this.businessId,
              mainBorrowerId: this.finBusuinessInfo.gysId
            })
            this.pledgorList = []
            this.pledgorList.push({
              name: this.finBusuinessInfo.gysCompanyName,
              creditCode: this.finBusuinessInfo.creditCode,
              gysId: this.finBusuinessInfo.gysId,
              contractData: data
            })
          }
        }
      } else {
        this.personsDatas = []
        await this.pledgorList.forEach((ele) => {
          this.$set(ele, 'pledgorData', [])
        })
        this.detailData.finContractInfosPlain = []
      }

      this.setPledgorData()
    },
    // 设置质押信息数据
    setPledgorData (type) {
      this.pledgorList.forEach((ele) => {
        const arr = []
        if (this.detailData.finContractInfosPlain) {
          this.detailData.finContractInfosPlain.forEach((eles) => {
            if (ele.gysId === eles.gysId) {
              arr.push(eles)
            }
          })
        }
        if (type === 'detail') {
          this.$set(ele, 'pledgorData', arr)
        } else {
          if (!ele.pledgorData) {
            this.$set(ele, 'pledgorData', arr)
          }
        }
        ele.pledgorData.forEach((item) => {
          // 质押信息
          // item.pledgeValue = item.pledgeValue ? item.pledgeValue : ''
          // item.relLoanAmount = item.relLoanAmount ? item.relLoanAmount : ''
          if (type === 'detail') {
            ele.contractData.forEach((eles) => {
              if (eles.keyId === item.finContractId) {
                item.keyIds = eles.keyId
              }
            })
          }
        })
      })
    },
    // 计算结束时间
    getRepayTime (data) {
      if (!data.financeTerm || !this.formData.preLoanDate) return
      const advanceRepayTerm = data.financeTerm
      const str = this.formData.preLoanDate.replace(/-/g, '/')
      const yearItem =
        parseInt(advanceRepayTerm) >= 12
          ? parseInt(parseInt(advanceRepayTerm) / 12)
          : 0
      const monthItem =
        parseInt(advanceRepayTerm) < 12
          ? parseInt(advanceRepayTerm)
          : parseInt(advanceRepayTerm) % 12
      var date = new Date(str)
      var year = date.getFullYear() + yearItem
      var month = date.getMonth() + monthItem + 1
      if (month > 12) {
        year++
        month -= 12
      }
      if (month < 10) {
        month = '0' + month
      }
      var date2 = new Date(year, month, 0)
      var day1 = date.getDate()
      var day2 = date2.getDate()
      if (day1 > day2) {
        day1 = day2
      }
      if (day1 < 10) {
        day1 = '0' + day1
      }
      return year + '-' + month + '-' + day1
    },
    // 融资决议制作
    async  saveData (type) {
      const passStatus = await this.api.checkTimeOutOrNot({ businessId: this.$route.query.keyId }).then((res) => {
        if (!res.data.status) {
          this.warning(res.data.errorMsg)
          this.$router.back()
          return false
        } else {
          return true
        }
      })
      if (!passStatus) {
        return
      }
      let state = true
      if (type !== 'save') {
        if (!this.formData.preLoanDate) {
          return this.warning('请选择预计放款时间')
        }
        if (!this.formData.meettingDate) {
          return this.warning('请选择上会日期')
        }
        const schemeForm = this.$refs.financingData.$refs.schemeFormData
        for (let i = 0; i < schemeForm.length; i++) {
          schemeForm[i].validate((valid) => {
            if (!valid) {
              state = false
            }
          })
        }
        this.$refs.accountAndService.$refs.superviseForm.validate((valid) => {
          if (!valid) {
            state = false
          }
        })
        this.$refs.accountAndService.$refs.factoringForm.validate((valid) => {
          if (!valid) {
            state = false
          }
        })

        // 财富资金验证保理结算账户
        this.$refs.accountAndService.$refs.settlementAccountForm.validate(
          (valid) => {
            if (!valid) {
              state = false
            }
          }
        )
        this.$refs.otherData.$refs.otherFrom.validate((valid) => {
          if (!valid) {
            state = false
          }
        })
        if (type !== 'generate') {
          // otherFile
          this.$refs.otherFile.validate((valid) => {
            if (!valid) {
              state = false
            }
          })
        }
        if (this.personalData.length === 0) {
          return this.warning('请添加个人担保')
        }
        // if (this.enterpriseData.length === 0) {
        //   return this.warning('请添加企业担保')
        // }

        if (!this.personalData[0].loanAssistanceOrgId) {
          return this.warning('请选择个人担保主体')
        }

        // if (!this.enterpriseData[0].loanAssistanceOrgId) {
        //   return this.warning('请选择企业担保主体')
        // }

        let alienatorStatus = true
        if (this.alienatorData.length === 0) {
          alienatorStatus = false
        }
        this.alienatorData.forEach((ele) => {
          if (
            !ele.finContractId ||
            !ele.finContractName ||
            (!ele.pledgeValue && ele.pledgeValue !== 0) ||
            (!ele.relLoanAmount && ele.relLoanAmount !== 0)
          ) {
            alienatorStatus = false
          }
        })
        if (!alienatorStatus) {
          return this.warning('请完善转让信息')
        }
        let newError = ''
        this.alienatorData.forEach((item) => {
          if (item.pledgeValue - item.relLoanAmount < 0) {
            alienatorStatus = false
            newError = '对应融资额不能大于转让价值'
          }
          if (item.finContractAmount - item.pledgeValue <= 0) {
            alienatorStatus = false
            newError = '转让价值不能大于等于合同对价'
          }
          // if (parseInt(item.pledgeValue) < parseInt(item.relLoanAmount)) {
          //   alienatorStatus = false
          //   newError = '对应融资额不能大于转让价值'
          // }
        })
        if (!alienatorStatus) {
          return this.warning(newError)
        }
        let status = true
        this.schemeInfoList.forEach((ele) => {
          if (
            this.finBusuinessInfo.gysCompanyName &&
            ele.mainBorrower === this.finBusuinessInfo.gysCompanyName
          ) {
            status = false
          }
        })
        if (!status && this.formData.financeType === 'SECOND_MODEL') {
          return this.warning('主借人不能相同')
        }
        let pledgorState = true
        this.pledgorList.forEach((ele) => {
          ele.pledgorData.forEach((eles) => {
            if (parseFloat(eles.relLoanAmount) > parseFloat(eles.pledgeValue)) {
              pledgorState = false
            }
          })
        })
        if (!pledgorState) {
          return this.warning('对应融资额不能大于质押价值')
        }
      }
      // 股东会决议有效性验证

      if (this.resultValidTimeType !== '0') { // 非单笔验证
        if (this.singleTransactionType === '1') { // 重出
          this.$refs.shareholderData.$refs.shareholderMeeting.$refs.ShareholderMeetingType.$refs.ShareholderMeetingType.validate((valid) => {
            if (!valid) {
              state = false
            }
          })
        }
        if (this.shareholderResultValidInfo) {
          if (!this.shareholderResultValidInfo.resultValidTimeStart) {
            this.warning('请完善股东会决议有效性信息')
            return false
          }
        } else {
          this.warning('请完善股东会决议有效性信息')
          return false
        }
        if (!state) {
          this.warning('请完善信息')
          return
        }
        // 融资方案  this.schemeInfoList[0].financeAmount
        // 股东会决议有效性 this.shareholderResultValidInfo.guaranteeQuota
        if (this.shareholderResultValidInfo.guaranteeQuota * 100000000 - this.schemeInfoList[0].financeAmount < 0) {
          this.warning('股东会决议担保额度不能小于融资金额')
          return false
        }
      }
      // 统一提示
      for (let i = 0; i < this.schemeInfoList.length; i++) {
        const ele = this.schemeInfoList[i]

        if (ele.surplusQuota < parseFloat(ele.financeAmount)) {
          return this.warning(
            ` 融资金额 ${ele.financeAmount}(元) 大于 资金方  ${ele.capitalSideName} 剩余额度 ${ele.surplusQuota}(元)`
          )
        }
      }
      const pledgorData = []
      this.pledgorList.forEach((ele) => {
        ele.pledgorData.forEach((eles) => {
          eles.plainPersonName = ele.name
          eles.plainPersonCreditCode = ele.creditCode
          pledgorData.push(eles)
        })
      })
      let stairRelLoanAmount = 0 // 一级对应融资额之和
      let secondRelLoanAmount = 0 // 二级对应融资金额之和
      if (this.pledgorList.length > 1) {
        // 二级模式下判断一级供应商对应融资额是否等于二级供应商融资金额之和
        this.pledgorList.forEach((ele, index) => {
          if (index === 0) {
            ele.pledgorData.forEach((eles) => {
              stairRelLoanAmount =
                stairRelLoanAmount + parseFloat(eles.relLoanAmount)
            })
          } else {
            ele.pledgorData.forEach((eles) => {
              secondRelLoanAmount =
                secondRelLoanAmount + parseFloat(eles.relLoanAmount)
            })
          }
        })
        if (stairRelLoanAmount !== secondRelLoanAmount && type !== 'save') {
          return this.warning(
            '一级供应商对应融资额之和不等于二级供应商融资金额之和'
          )
        }
      }
      if (!state) return this.warning('请完善信息')
      this.pass = true
      let repayAmountPass = false
      this.schemeInfoList.forEach((ele) => {
        ele.repayTime = this.getRepayTime(ele)
        if (ele.repayTypeCode === '0' || ele.repayTypeCode === '2') {
          // 一次性还本付息 || 一次性还本按月付息
          ele.finRepayStyleDTO.repayTime = ele.repayTime
          ele.chargeParty = ele.mainBorrower
        } else if (ele.repayTypeCode === '1') {
          // 分批还款
          if (
            !ele.finRepayStyleDTO.repayAmount &&
            !ele.finRepayStyleDTO.repayRate
          ) {
            repayAmountPass = true
          }
          ele.finRepayStyleDTO.chargeParty = ele.mainBorrower
          ele.repayStatus = ele.finRepayStyleDTO.repayStatus
          ele.repayAmount = ele.finRepayStyleDTO.repayAmount
          ele.chargeParty = ele.mainBorrower
        }
        if (!ele.serviceRateState) {
          ele.finServiceCollectInfo = []
          ele.serviceRate = parseFloat(ele.serviceRateStr)
        } else {
          ele.serviceRate = ''
        }
      })
      if (type !== 'save') {
        if (repayAmountPass) {
          this.warning('请完善分批还款信息')
          return false
        }
      }
      // this.shareholderResultValidInfo.resultValidTimeStart = this.shareholderResultValidInfo.time[0]
      // this.shareholderResultValidInfo.resultValidTimeEnd = this.shareholderResultValidInfo.time[1]
      this.shareholderResultValidInfo.orgId = this.finBusuinessInfo.gysId
      const params = {
        resultValidTimeType: this.resultValidTimeType === '0' ? this.resultValidTimeType : this.singleTransactionType, // 股东会决议有效性 0.单笔(默认)1.重出 2.续用
        shareholderResultValidInfo: this.shareholderResultValidInfo, // 股东会决议有效性信息DTO 重出和需用才有
        finRepayBankAccountInfoDTO: this.finRepayBankAccountInfoDTO,
        commitmentLetter: this.commitmentLetter,
        finCompanyGuaranteeInfos: this.enterpriseData,
        finContractInfosPlain: pledgorData,
        finSuperviseBankAccountInfoDTO: this.accountData,
        finContractInfosSupplement: this.contractInfoData,
        finContractInfosTransfer: this.alienatorData,
        finFactoringServiceInfo: this.serviceData,
        finPersonnalGuaranteeInfos: this.personalData,
        finPlanInfos: this.schemeInfoList,
        businessId: this.businessId,
        instanceId: this.instanceId,
        finReviewStatus: this.detailData.finReviewStatus,
        // finReviewOpinionInfo: this.detailData.finReviewOpinionInfo,
        finBusuinessInfo: this.detailData.finBusuinessInfo,
        fileListInfo: this.detailData.fileListInfo,
        keyId: this.keyId,
        ...this.formData
      }

      if (type === 'save') {
        this.save(params)
      } else if (type === 'generate') {
        // 文件生成
        this.save(params, 'generate')
      } else {
        this.submit(params)
      }
    },
    // 文件生成
    generate (params) {
      this.api.generateFile(params).then((res) => {
        if (res.code === '0') {
          this.success('文件生成成功')
          this.generatefileId = res.data.keyId

          this.pass = false
          this.$set(this.fileFormData, 'fileName', res.data.fileName)
        }
      })
    },
    // 融资决议制作数据保存
    save (params, type) {
      params.fileListInfo.fileName = this.fileFormData.fileName
      params.fileListInfo.fileId = this.generatefileId
      this.api.save(params).then((res) => {
        if (res.success) {
          this.pass = false

          if (type === 'generate') {
            this.generate(params)
          } else {
            this.success('保存成功')
            this.getBatch()
          }
        }
      })
    },
    // 融资决议制作数据提交
    submit (params) {
      params.fileListInfo.fileName = this.fileFormData.fileName
      params.fileListInfo.fileId = this.generatefileId
      this.api.submit(params).then((res) => {
        if (res.success) {
          this.pass = false
          this.success('提交成功')
          this.close()
        }
      })
    },
    // 文件预览
    previewShow () {
      this.fileType = this.fileFormData.fileName.split('.').pop()
      if (!this.fileType || !allSupportFileTypes.includes(this.fileType)) {
        this.warning(`不能预览的文件类型：[${this.fileType}]`)
        return
      }
      // this.previsible = true
      this.$nextTick(() => {
        this.count++
      })
    },
    // 取消
    close () {
      this.$router.back()
      // this.$router.push('/business/financing-decision-manage')
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
