<template>
  <div>
    <el-drawer
      :with-header="false"
      :visible.sync="visible"
      direction="rtl"
      :before-close="handleClose"
      :modal="false"
      :wrapperClosable='false'
      :size='size'
    >
    <div class="closeBtn" @click="handleClose">
      <i class="iconfont iconyou"></i>
    </div>
      <auditTimeline :dataList='dataList' :auditType="auditType" :name="name" :auditInfo="auditInfo"></auditTimeline>
    </el-drawer>
  </div>
</template>
<script>
import auditTimeline from '@/pages/business/components/auditTimeline.vue'
export default {
  components: { auditTimeline },
  data () {
    return {}
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 450
    },
    dataList: {
      type: Array,
      default: () => []
    },
    auditInfo: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: '申请详情'
    },
    auditType: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClose () {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.closeBtn{
  width: 31px;
  height: 32px;
  border-radius:5px 0 0 5px ;
  background: #2862E7;
  position: absolute;
  top: 12px;
  left: -30px;
  z-index: 999999999999;
  cursor: pointer;
  color: #FFF;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
/deep/ .el-drawer {

border: 1px solid rgba(255, 217, 185, 0.3);
  box-shadow: -10px 0px 10px 0px rgba(246, 184, 83, 0.2);
  overflow: visible;
}
/deep/ .el-drawer__wrapper{
  top: 100px;
  right: 0px;
  width: 480px;
  left: inherit;
}
</style>
