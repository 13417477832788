import Rest from '@/utils/http/Rest'
import http from '@/utils/http'

export default class Parameter extends Rest {
  /**
   * 获取表格列表数据
   * @param params
   * @returns {AxiosPromise}
   */
  getTableList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/page`,
      params
    })
  }

  /**
   * 导出表格列表
   * @returns {AxiosPromise}
   */
  exportList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/export`,
      responseType: 'blob',
      params
    })
  }

  /**
   *本月本周收件数量
   * @param params
   * @returns {AxiosPromise}
   */
  riskCount (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/count`,
      params
    })
  }

  /**
   *修改备注及暂缓状态
   * @param data
   * @returns {AxiosPromise}
   */
  editStatusInfo (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveOrUpdateFinSuspendedStatusInfo`,
      data
    })
  }
}
