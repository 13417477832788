
<!-- 新版大标题 -->
<template>
  <div class="texttitle">
    <div class="title">
     <div class="leftMain">
       <span class="red" v-if="required">*</span>
       <div class="index">{{index}}</div> {{ label }}<span class="tips">{{ tips }}</span>
        <div class="left">
          <slot name= 'left'></slot>
          <!-- 屏蔽信息提示 -->
          <!-- <slot name= 'titleTips' v-if="titleTips">
            <div class="tipBox">
               <i class="iconfont iconwenhao"></i>
               <div class="tipscontent">
                <div v-for="item in titleTipsArr" :key="item">{{item}}</div>
               </div>
            </div>
          </slot> -->
        </div>
     </div>
      <div class="right"><slot name= 'right'></slot></div>
    </div>
    <div class="fgx"></div>
  </div>
</template>
<script>
export default {
  name: 'textTitle',
  props: {
    index: String,
    label: String,
    tips: String,
    required: Boolean,
    titleTips: String
  },
  data () {
    return {}
  },
  computed: {
    titleTipsArr () {
      return this.titleTips.split('//')
    }
  }

}
</script>
<style lang="scss" src='./text-title.scss' scoped></style>
